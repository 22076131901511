<template>
  <div class="main">
    <div class="activity-list">
      <div class="title">
        <span class="date">开展日期</span>
        <span class="type">活动类型</span>
        <span class="theme">课程主题</span>
        <span class="num">参加人数</span>
      </div>

      <vue-seamless-scroll v-if="listData.length" :data="listData" class="warp">
        <div
          style="display: flex"
          v-for="(item, index) in listData"
          :key="index"
          class="warp-item"
        >
          <span class="date">{{
            item.startTime ? item.startTime.split(' ')[0] : '暂无'
          }}</span>
          <span class="type">{{ item.category }}</span>
          <span class="theme">{{ item.topic }}</span>
          <span class="num">{{ item.num }}</span>
        </div>
      </vue-seamless-scroll>
      <div v-if="!listData.length" class="noMessage">暂无数据</div>
    </div>
  </div>
</template>

<script>
import dachuanData from '@/assets/dachuanData/index.js'
export default {
  data() {
    return {
      listData: [],
      listDataDs: dachuanData.ds_data.listData,
      listDataGc: dachuanData.gc_data.listData,
    }
  },
  computed: {
    type() {
      return this.$route.query.type
    },
  },
  mounted() {
    this.getActivity()
  },
  methods: {
    getActivity() {
      if (this.type == 'ds') {
        this.listData = this.listDataDs
      } else {
        this.listData = this.listDataGc
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 9.9rem;
  background: url('~@/assets/images/institution_images/activityBg.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  margin-top: 0.41rem;
  overflow: hidden;
}
.activity-list {
  margin: 0rem 0.41rem 0.5rem 0.41rem;
  width: calc(100% - 0.82rem);
  height: 7.58rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
  margin-top: 1.5rem;
  z-index: 99;
}
.title {
  display: flex;
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.5rem;
  border-radius: 0.08rem;
  border-bottom: 0.04rem solid #213060;
  padding: 0.33rem 0.58rem;
  z-index: 99;
}
.date,
.type {
  flex: 2;
}
.theme {
  flex: 4;
}
.num {
  flex: 1;
}
.warp {
  z-index: 99;
  overflow: hidden;
  margin: 0 0.5rem;
  height: calc(100% - 1.25rem);
}
.warp .warp-item {
  height: 1.5rem;
  span {
    display: flex;
    align-items: center;
  }
}
.warp .date,
.warp .type,
.warp .theme,
.warp .num {
  font-size: 0.58rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b4d3fe;
  line-height: 0.58rem;
}
.noMessage {
  font-size: 1.2rem;
  color: #2d3769;
  text-align: center;
  margin-top: 2.5rem;
}
</style>
